import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { buildBasicRows } from 'utils/functions';
import ComparisonTableBody from './components/ComparisonTableBody';
import ComparisonTableHeader from './components/ComparisonTableHeader';
import MobileComparisonTable from './components/MobileComparisonTable';
import comparisonTableShape from 'shapes/components/comparisonTableShape';

const COMPARISON_ROW = 'Comparison Row';

const Table = styled.table`
  font-weight: 400;
  margin-bottom: 1em;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  
  ${ifProp({$mobileStyle: 'Comparison Row'}, css`
    display: none;

   ${props => props.theme.breakpoints.up('md')} {
      display: table;
    }
  `)}

  ${switchProp('$tableWidth', {
    'full width': css`
      width: 90%;
      
      ${props => props.theme.breakpoints.up('md')} {
        width: 85%;
      }
      
      ${props => props.theme.breakpoints.up('lg')} {
        width: 75%;
      }
    `,
    '1/2 width': css`
      width: 90%;

      ${props => props.theme.breakpoints.up('md')} {
        width: 50%;
      }
    `,
  }, css`width: 75%;`)}
`;

ComparisonTable.propTypes = {
  className: PropTypes.string,
  data: comparisonTableShape,
};

function ComparisonTable({ data, className }) {
  if (!data) {
    return null;
  }

  const {
    mobileTableStyle,
    noPadding,
    tableHeaders,
    tableColumns,
    tableWidth
  } = data;
  const rows = buildBasicRows(tableColumns);

  return (
    <div className={className}>
      <Table
        $mobileStyle={mobileTableStyle}
        $noPadding={noPadding}
        $tableWidth={tableWidth}
      >
        {tableHeaders.length > 0 && (
          <ComparisonTableHeader
            headers={tableHeaders}
            mobileStyle={mobileTableStyle}
            noPadding={noPadding}
          />
        )}
        {tableColumns.length > 0 && (
          <ComparisonTableBody
            columns={tableColumns}
            mobileStyle={mobileTableStyle}
            rows={rows}
            noPadding={noPadding}
          />
        )}
      </Table>
      {mobileTableStyle === COMPARISON_ROW && (
        <MobileComparisonTable headers={tableHeaders} columns={tableColumns} />
      )}
    </div>
  );
}

export default React.memo(ComparisonTable);
