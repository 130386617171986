import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import styled, { css } from 'styled-components';
import { prop, switchProp, ifProp } from 'styled-tools';
import { columnShape, rowShape } from 'shapes/components/comparisonTableShape';

const TableData = styled.td`
  border: 1px solid ${prop('theme.colors.black')};
  font-family: ${prop('theme.typography.fontFamily')};
  padding: 0.75em;
  vertical-align: top;

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 0.5em;
  }

  ${ifProp('$noPadding', css`
    padding: 0;
  `)}

  ${switchProp('$mobileStyle', {
  'Standard Table': css`
    font-size: ${ifProp('$fontSmaller', '0.75rem', '0.875rem')};

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${ifProp('$fontSmaller', '1rem', '1.125rem')};
    }

    && {
      p {
        font-size: ${ifProp('$fontSmaller', '0.75rem', '0.875rem')};
        margin-bottom: 0;

        ${props => props.theme.breakpoints.up('md')} {
          font-size: ${ifProp('$fontSmaller', '1rem', '1.125rem')};
        }
      }
    }
  `,
  'Comparison Row': css`
    font-size: 1rem;

    && {
      p {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  `,
})}

  ul {
    padding-left: 1rem;
    margin-bottom: 0;
  }

  && {
    a {
      color: ${prop('theme.colors.black')};

      &:hover {
        color: ${prop('theme.colors.ttRed')};
      }
    }

    h3 {
      font-size: 1rem;

      ${props => props.theme.breakpoints.up('md')} {
        font-size: 1.5rem;
      }
    }
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${props => props.theme.breakpoints.up('md')} {
      flex-direction: row;
    }

    p {
      margin-bottom: 0;
    }
  }

  .inline-icon {
    width: 1.25rem;
  }
`;

ComparisonTableBody.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  mobileStyle: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(rowShape)),
  noPadding: PropTypes.bool,
};

function ComparisonTableBody({ columns, mobileStyle, rows, noPadding }) {
  return (
    <tbody>
      {rows.map((row, index) => {
        const colCount = columns.length;
        const itemCount = row.length;
        const colSpan = itemCount !== colCount ? colCount : 1;

        return (
          <tr key={index}>
            {row.map((item, index) => (
              <TableData
                colSpan={colSpan}
                key={index}
                $fontSmaller={colCount > 2}
                $mobileStyle={mobileStyle}
                $noPadding={noPadding}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}
              />
            ))}
          </tr>
        )
      })}
    </tbody>
  );
}

export default React.memo(ComparisonTableBody);
