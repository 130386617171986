import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { buildComparisonRows } from 'utils/functions';
import ComparisonRow from './ComparisonRow';
import { columnShape, headerShape } from 'shapes/components/comparisonTableShape';

const Table = styled.div`
  display: block;
  font-weight: 400;
  padding-bottom: 1.875rem;

  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

MobileComparisonTable.propTypes = {
  columns: PropTypes.arrayOf(columnShape),
  headers: PropTypes.arrayOf(headerShape),
};

function MobileComparisonTable({ columns, headers }) {
  const rows = buildComparisonRows(columns, headers);

  return (
    <Table>
      {rows?.map((row, index) => (
        <ComparisonRow
          key={index}
          firstRow={row.header}
          name={row.name}
          data={row.data}
        />
      ))}
    </Table>
  );
}

export default React.memo(MobileComparisonTable);
