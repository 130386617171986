import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'
import { prop, ifProp } from 'styled-tools';

const StyledRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  display: flex;
  font-family: ${prop('theme.typography.fontFamily')};
  height: 3.125rem;
  justify-content: space-between;
  padding: 0.625rem 0.625rem 0 0.625rem;

  ${ifProp(
    'firstRow',
    css`
      background: ${prop('theme.colors.lighterGray')};
      > div {
        &:last-child {
          color: ${prop('theme.colors.primaryRed')};
        }
      }
    `
  )}

  > div {
    &:first-child {
      font-size: 1rem;
    }

    &:last-child {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  sup {
    padding-left: 0.25rem;
  }
`;

const propTypes = {
  data: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  firstRow: PropTypes.bool,
  footnote: PropTypes.number,
};

function ComparisonRow ({ name, data, firstRow, footnote }) {
  return (
    <StyledRow firstRow={firstRow}>
      <div>
        {name}
        {footnote && (
          <sup>{footnote}</sup>
        )}
      </div>
      <div>{data}</div>
    </StyledRow>
  )
}

ComparisonRow.propTypes = propTypes;

export default ComparisonRow;
