import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import { headerShape } from 'shapes/components/comparisonTableShape';

const TableHeader = styled.th`
  background: ${ifProp('$redBackground', prop('theme.colors.ttRed'), 'transparent')};
  border: 1px solid ${prop('theme.colors.black')};
  color: ${ifProp('$redBackground', prop('theme.colors.white'), prop('theme.colors.black'))};
  font-family: ${prop('theme.typography.fontFamily')};
  font-weight: 700;
  padding: 0.75em;
  text-align: ${ifProp('$alignment', prop('$alignment'), 'center')};
  vertical-align: baseline;

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 0.5em;
  }

  ${ifProp('$noPadding', css`
    padding: 0;
  `)}

  ${ifProp('$noBorder', css`
    border: none;
  `)}

  ${ifProp('$width', css`
    width: ${prop('$width')}%;
  `)}

  ${switchProp('$mobileStyle', {
  'Standard Table': css`
      font-size: ${ifProp('$fontSmaller', '0.75rem', '1rem')};

      ${props => props.theme.breakpoints.up('md')} {
        font-size: ${ifProp('$fontSmaller', '1rem', '1.125rem')};
      }
    `,
  'Comparison Row': css`
      font-size: 1rem;
    `,
})}
`;

ComparisonTableHeader.propTypes = {
  headers: PropTypes.arrayOf(headerShape),
  mobileStyle: PropTypes.string,
  noPadding:PropTypes.bool,
  noBorder:PropTypes.bool,
};

function ComparisonTableHeader({ headers, mobileStyle, noPadding, noBorder }) {
  const fontSmaller = headers?.length > 2;

  return (
    <thead>
      <tr>
        {headers.map((header, index) => {
          const {
            content,
            footnoteReference,
            headerAlignment,
            headerType,
            headerWidth,
            redBackground,
            noBorder,
          } = header;

          return (
            <TableHeader
              key={index}
              $alignment={headerAlignment}
              $mobileStyle={mobileStyle}
              $fontSmaller={fontSmaller}
              $redBackground={redBackground}
              $type={headerType}
              $width={headerWidth}
              $noPadding={noPadding}
              $noBorder={noBorder}
            >
              <>
                {content}
                {footnoteReference && (
                  <sup>{' '}{footnoteReference}</sup>
                )}
              </>
            </TableHeader>
          )
        })}
      </tr>
    </thead>
  );
}

export default React.memo(ComparisonTableHeader);
